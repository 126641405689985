.EggTimer-start {
  align-items: center;
  background-color: #333333;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  /*justify-content: center;*/
  min-height: 100vh;
  text-align: center;
}

.EggTimer-start-flourish {
  background: linear-gradient(#333333, transparent),
    radial-gradient(50vw at 25vw max(25vw, 110%), #00cdff 0%, transparent 60%),
    radial-gradient(50vw at 50vw max(25vw, 110%), #ff0095 0%, transparent 60%),
    radial-gradient(50vw at 75vw max(25vw, 110%), #ffdf00 0%, transparent 60%);
  bottom: 0;
  height: 25vh;
  position: fixed;
  width: 100vw;
  z-index: 0;
}

.EggTimer-start-content {
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-top: 2vh;
  z-index: 100;
}

.EggTimer-start-icon {
  height: 228px;
  margin: 1em;
  width: 228px;
}

.EggTimer-start-welcome {
  margin-top: 2em;
}

.EggTimer-start-welcome p {
  font-size: 18px;
  margin-bottom: 1.25em;
  margin-top: -0.75em;
}

.EggTimer-start-main {
  min-width: 320px;
}

.EggTimer-start-time-input {
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 1em;
  width: 320px;
}

.EggTimer-start-time-input-wrapper {
  position: relative;
  width: calc(80% - 8px);
}

.EggTimer-start-time-input-label {
  color: #aaaaaa;
  font-size: 24px;
  line-height: 1.8em;
  position: absolute;
  text-align: center;
  width: 1em;
}

.EggTimer-start-time-input input {
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
  font-size: 24px;
  height: 2em;
  margin: 0;
  padding: 0.5em 0.5em 0.5em 1em;
  width: 100%;
}

.EggTimer-start-time-input input:focus {
  outline: medium solid #ff0095;
  outline-offset: -2px;
}

.EggTimer-start-time-input button {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  width: 20%;
}

.EggTimer-start-time-input button.validTime {
  background-color: #ff0095;
  border-color: #ff0095;
  cursor: pointer;
}

.EggTimer-start-time-input button:focus {
  outline: medium solid #fffd00;
  outline-offset: -2px;
}

.EggTimer-start-quick-time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5em;
  min-width: 320px;
}

.EggTimer-start-quick-time a,
.EggTimer-start-meta button {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid white;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  flex: 1 auto;
  font-size: 14px;
  padding: 0.75em;
  text-decoration: none;
}

.EggTimer-start-quick-time a:not(:first-of-type),
.EggTimer-start-meta button:not(:first-of-type) {
  margin-left: 8px;
}

.EggTimer-start-quick-time a:hover,
.EggTimer-start-meta button:hover {
  background-color: #ff0095;
  border-color: #ff0095;
}

.EggTimer-start-quick-time a:focus,
.EggTimer-start-meta button:focus {
  background-color: #ff0095;
  border-color: #ff0095;
  outline: medium solid #ffdf00;
}

.EggTimer-start-colophon {
  color: white;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 2rem;
  z-index: 100;
}

.EggTimer-start-meta {
  color: white;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin: 2.5rem 0;
  max-width: 320px;
  width: 100%;
  z-index: 100;
}

.EggTimer-start-colophon a:link,
.EggTimer-start-colophon a:visited {
  color: #ffdf00;
}
