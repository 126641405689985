.EggTimer-timer {
  align-items: center;
  background: #ffffff;
  color: #333333;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
  text-align: center;
}

.EggTimer-timer audio {
  display: none !important;
}

.EggTimer-timer-wrapper {
  align-items: stretch;
  background: #333333;
  display: flex;
  flex: 1 auto;
  justify-content: center;
  overflow: auto;
  width: 100vw;
}

.EggTimer-load-icon {
  margin: calc(2vh + 1em) 1em 1em 1em;
  opacity: 0.5;
}
