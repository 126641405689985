.EggTimer-settings p {
  line-height: 32px;
  margin-bottom: 0.2em;
  margin-top: 0.2em;
}

.EggTimer-settings div {
  margin-bottom: 2em;
}

.EggTimer-settings p span.label {
  color: #ffffff;
  font-weight: bold;
  margin-right: 16px;
}

.EggTimer-settings p span.value {
  color: #888888;
  margin-left: 16px;
}

.EggTimer-settings p span button {
  cursor: pointer;
  height: 32px;
}

.EggTimer-settings p select {
  height: 32px;
}

.EggTimer-settings .switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 32px;
}

.EggTimer-settings .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.EggTimer-settings .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.EggTimer-settings .slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.EggTimer-settings input:checked + .slider {
  background-color: #00cdff;
}

.EggTimer-settings input:focus + .slider {
  box-shadow: 0 0 1px #00cdff;
}

.EggTimer-settings input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.EggTimer-settings .slider.round {
  border-radius: 32px;
}

.EggTimer-settings .slider.round:before {
  border-radius: 50%;
}
