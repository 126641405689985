.EggTimer-timer-bar {
  background-color: #eeeeee;
  border-top: 2px solid #aaaaaa;
  box-sizing: border-box;
  color: #111111;
  height: 40px;
  padding: 4px 8px;
  position: relative;
  transition: height 300ms ease-in-out, padding 300ms ease-in-out;
  width: 100vw;

  --toggle-height: 16px;
}

.EggTimer-timer-bar.collapsed {
  height: 0;
  padding: 0;
}

.EggTimer-timer-bar.collapsed .EggTimer-timer-bar-buttons a,
.EggTimer-timer-bar.collapsed .EggTimer-timer-bar-buttons button,
.EggTimer-timer-bar.collapsed .EggTimer-timer-bar-buttons select {
  opacity: 0;
  outline: none;
  pointer-events: none;
  transition: opacity 100ms ease-in-out;
  user-select: none;
}

.EggTimer-timer-bar.collapsed .EggTimer-timer-bar-buttons a:focus,
.EggTimer-timer-bar.collapsed .EggTimer-timer-bar-buttons button:focus,
.EggTimer-timer-bar.collapsed .EggTimer-timer-bar-buttons select:focus {
  outline: none;
}

.EggTimer-timer-bar.collapsed button.EggTimer-timer-bar-toggle-collapse {
  opacity: 0.5;
}

.EggTimer-timer-bar.collapsed button.EggTimer-timer-bar-toggle-collapse:hover {
  opacity: 1;
}

.EggTimer-timer-bar-buttons {
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.EggTimer-timer-bar-buttons > :not(:first-child) {
  margin-left: 8px;
}

.EggTimer-timer-bar-buttons a,
.EggTimer-timer-bar-buttons button {
  align-items: center;
  cursor: pointer;
  display: flex;
  width: 24px;
}

.EggTimer-timer-bar-buttons a svg,
.EggTimer-timer-bar-buttons button svg {
  height: 100%;
  width: 100%;
}

.EggTimer-timer-bar-buttons a,
.EggTimer-timer-bar-buttons button {
  background: none;
  border-radius: 4px;
  border: 1px solid #333333;
  color: inherit;
  cursor: pointer;
  font-size: 12px;
  min-width: fit-content;
  outline: inherit;
  overflow: hidden;
  padding: 1px 8px;
  text-decoration: none;
}

.EggTimer-timer-bar a:focus,
.EggTimer-timer-bar button:focus {
  outline: medium solid #00cdff;
  outline-offset: -2px;
}

.EggTimer-timer-bar a:link,
.EggTimer-timer-bar a:visited {
  color: #111111;
}

.EggTimer-timer-bar-buttons a:hover,
.EggTimer-timer-bar-buttons button:hover {
  background-color: #ff0095;
  border-color: #ff0095;
  color: white;
}

.EggTimer-timer-bar-buttons button:disabled {
  color: #aaaaaa;
}

.EggTimer-timer-bar-buttons a svg,
.EggTimer-timer-bar-buttons button svg {
  float: left;
  width: 2em;
}

.EggTimer-timer-bar-buttons a span,
.EggTimer-timer-bar-buttons button span {
  float: right;
  margin-left: 4px;
  pointer-events: none;
}

.EggTimer-timer-bar-progress {
  background-color: #ff0095;
  height: 2px;
  left: 0;
  position: absolute;
  top: -2px;
  transition: width 1000ms linear;
  width: 0;
}

.EggTimer-timer-bar select:focus {
  outline: medium solid #00cdff;
  outline-offset: -2px;
}

.EggTimer-timer-bar-buttons label {
  font-size: 1em;
}

.EggTimer-timer-bar-buttons select {
  background-color: #eeeeee;
  border-color: #111111;
  border-radius: 4px;
  color: #111111;
  padding: 1px 8px;
}

.EggTimer-timer-bar-buttons select:hover {
  background-color: #ff0095;
  border-color: #ff0095;
  color: white;
}

/*todo fix*/
#notifyprompt {
  padding: 8px;
}

button.EggTimer-notify-prompt {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-size: 1em;
  padding: 8px;
  width: fit-content;
}

button.EggTimer-notify-prompt:hover {
  background-color: #ff0095;
  border-color: #ff0095;
}

.EggTimer-timer-bar-toggle {
  align-items: flex-end;
  display: flex;
  font-size: 12px;
  height: var(--toggle-height);
  justify-content: center;
  left: 0;
  position: absolute;
  top: calc(-1 * var(--toggle-height));
  width: 100%;
}

button.EggTimer-timer-bar-toggle-collapse {
  background-color: #eeeeee;
  border-radius: 4px 4px 0 0;
  border: none;
  height: 100%;
  opacity: 1;
  overflow: hidden;
  width: 100px;
}

button.EggTimer-timer-bar-toggle-collapse:hover {
  height: 110%;
  opacity: 1;
}

a.EggTimer-timer-bar-back {
  display: none;
}

button.EggTimer-timer-bar-help {
  display: none;
}

@media (min-width: 420px) {
  a.EggTimer-timer-bar-back {
    display: initial;
  }

  button.EggTimer-timer-bar-help {
    display: initial;
  }
}
