.UI-toggle-switch {
  border-radius: 4px;
  border: 1px solid #333333;
  display: flex;
  min-width: fit-content;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: fit-content;
}

.UI-toggle-switch:focus {
  outline: medium solid #00cdff;
  outline-offset: -2px;
}

.UI-toggle-switch:hover {
  border-color: #ff0095;
}

.UI-toggle-switch button {
  border-radius: 0;
  border: none;
  display: inline-block;
  height: 100%;
  width: 50%;
}

.UI-toggle-switch button.selected {
  background-color: #777777;
  -webkit-box-shadow: inset 0px 0px 5px #333333;
  -moz-box-shadow: inset 0px 0px 5px #333333;
  box-shadow: inset 0px 0px 5px #333333;
  color: #ffdf00;
}

.UI-toggle-switch button:focus {
  outline: none;
}

button.UI-toggle-switch-on {
  border-left: none;
  margin-left: 0;
}

button.UI-toggle-switch-off {
  border-right: none;
  margin-right: 0;
}
