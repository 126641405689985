html,
body {
  background-color: #333333;
  margin: 0;
  padding: 0;
}

.EggTimer {
  background-color: #00cdff;
  min-width: 100vw;
  min-height: 100vh;
}
