.EggTimer-settings-and-help {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
}

.EggTimer-settings-and-help a,
.EggTimer-settings-and-help a:visited {
  color: #ff0095;
  font-weight: bold;
  text-decoration: none;
}

.EggTimer-settings-and-help a:hover {
  text-decoration: #ff0095 underline;
}

.EggTimer-settings-and-help a:focus {
  background-color: #ff0095;
  border-color: #ff0095;
  color: #ffffff;
  outline: medium solid #ffdf00;
}

.EggTimer-settings-and-help-content {
  background-color: #333333;
  border: 1px solid #ff0095;
  border-radius: 4px;
  box-sizing: border-box;
  color: white;
  height: 80vh;
  min-width: 304px;
  overflow: auto;
  padding: 16px;
  position: relative;
  width: 60vw;
}

.EggTimer-settings-and-help-content .section {
  margin-bottom: 3rem;
}

.EggTimer-settings-and-help-content h1 {
  border-bottom: 4px solid white;
  width: fit-content;
}

.EggTimer-settings h1 {
  border-color: #00cdff;
}

.EggTimer-help h1 {
  border-color: #fffd00;
}

.EggTimer-tutorial h1 {
  border-color: #ff0095;
}

.EggTimer-settings-and-help-content article > h2 {
  color: #ffffff;
  margin-bottom: 0;
}

.EggTimer-settings-and-help-content article > h4 {
  color: #ffffff;
  font-size: 1.2em;
  margin-bottom: 0.5em;
}

.EggTimer-settings-and-help-content article b {
  color: #ffffff;
}

.EggTimer-settings-and-help-content article .parameter {
  margin-bottom: 2em;
}

.EggTimer-settings-and-help-content article {
  color: #cccccc;
  font-size: 1.2em;
  margin-bottom: 0.5em;
}

.EggTimer-settings-and-help-content code {
  background-color: #111111;
  color: #ffffff;
  font-family: Courier New, monospace;
  font-size: 1em;
  white-space: pre-wrap;
}

.EggTimer-settings-and-help-content code.example {
  display: block;
}

.EggTimer-settings-transition-enter {
  opacity: 0.01;
}

.EggTimer-settings-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.EggTimer-settings-transition-exit {
  opacity: 1;
}

.EggTimer-settings-transition-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.EggTimer-settings-close {
  background-color: #333333;
  font-size: 2em;
  position: sticky;
  top: 0;
  width: 100%;
}

.EggTimer-settings-close > svg {
  background-color: #333333;
  border-radius: 0.5em;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
