.EggTimer-user-interaction-nudge {
  background-color: #eeeeee;
  border-radius: 0 0 4px 4px;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6));
  max-width: 70vw;
  padding: 16px;
  position: fixed;
  top: 0;
  width: fit-content;
}

.EggTimer-user-interaction-nudge span {
  align-items: center;
  justify-content: center;
  display: flex;
}

.EggTimer-user-interaction-nudge span svg {
  height: 1em;
  margin: 0.2em;
  color: #ff0095;
}

.EggTimer-user-interaction-nudge p {
  font-size: 0.6em;
}

.EggTimer-user-interaction-nudge button {
  background-color: #333333;
  border: 1px solid white;
  border-radius: 4px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  /*filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6));*/
  padding: 0.75em;
  margin: 0 0.75em;
}

.EggTimer-user-interaction-nudge button:hover,
.EggTimer-user-interaction-nudge
  button.EggTimer-user-interaction-nudge-permanent:hover {
  background-color: #ff0095;
  border-color: #ff0095;
  color: white;
}

.EggTimer-user-interaction-nudge button:focus,
.EggTimer-user-interaction-nudge
  button.EggTimer-user-interaction-nudge-permanent:focus {
  background-color: #ff0095;
  border-color: #ff0095;
  outline: medium solid #fffd00;
  outline-offset: -2px;
}

.EggTimer-user-interaction-nudge
  button.EggTimer-user-interaction-nudge-permanent {
  background-color: transparent;
  border-color: #333333;
  color: #333333;
}

.EggTimer-nudge-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.EggTimer-nudge-transition-appear {
  transform: translateY(-100%);
}

.EggTimer-nudge-transition-appear-active {
  transform: translateY(0);
  transition: transform 300ms ease-out;
}

.EggTimer-nudge-transition-exit {
  transform: translateY(0);
}

.EggTimer-nudge-transition-exit-active {
  transform: translateY(-100%);
  transition: transform 300ms ease-in;
}
